<template>
  <div class="container-fluid">
    <!-- Title -->
    <div style="display: flex;">
      <div class="text-left" style="display: flex;">
        <div class="mr-2">
          <img class="mr-2" src="../../assets/icons/calendar.svg" width="12px" height="14px" />
          <span class="meeting-font-medium">
            {{formatEventDateTime(event.start_datetime, event.end_datatime)}}</span>
        </div>
        <div class="ml-2">
          <img class="mr-2" src="../../assets/icons/location.svg" style="width: 10px; height: 14px;" />
          <span class="meeting-font-medium">{{venue.venue_name ? venue.venue_name : ''+ " " + venue.venue_address ? venue.venue_address : ''}}</span>
        </div>
      </div>
    </div>
    <div class="centered" style="width: 800px;">
      <div style="display: flex;">
        <!-- Speaker -->
        <div class="mr-2" style="align-self: center;">
          <div 
            v-if="GET_ROOM_MEETING_INFO_DATA.length!=0"
            style="width: 52px; height: 61px; border: 1px solid #c7c7c7c7; position: relative;
              border-top: 5px solid #c7c7c7c7;"
            @click="addChairman"
            class="tooltips-top"
          >
            <div 
              style="position: absolute;
                top: 50%;
                left: 50%;
                margin-right: -50%;
                transform: translate(-50%, -50%);"
              v-if="chairMain.id"
            >
              <avatar
                v-if="chairMain.url_avatar"
                :src="chairMain.url_avatar"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="35"
              ></avatar>
              <avatar
                v-else
                :username="chairMain.full_name"
                backgroundColor="#e5e5e5"
                color="#0097A7"
                :size="35"
              ></avatar>
            </div>
            <div style="font-size: 14px" class="tooltiptext-top" v-if="chairMain.id">
              <p  class="p-0 m-0" style="color: #000000">{{chairMain.full_name}}</p>
              <p  class="p-0 m-0" style="color: #707070">{{chairMain.company_position}}</p>
              <p  class="p-0 m-0" style="color: #707070">{{chairMain.company_name}}</p>
            </div>
          </div>
        </div>
        <!-- All attendee in meeting room -->
        <div>
          <div class="seatstop">
            <!-- Attendee -->
            <div class="col-12" v-for="(rowTop, index) in seatsTop" :key="rowTop+index">
              <div
                v-for="(item, index) in rowTop.data"
                :key="item + index"
                style="width: 52px; height: 61px; border: 1px solid #CCCCCC; display: inline-block;
               margin: 5px; border-top: 5px solid #c7c7c7c7; position: relative;"
                @click="showModalAddUserForSeats(item, index, rowTop.code_alpha)"
                class="tooltips-bottom"
              >
                <div
                  class="seats-top-user"
                >
                  <div v-if="item.user!=undefined && item.user!=null && item.user.id!=null">
                    <avatar
                      v-if="item.user.url_avatar"
                      :src="item.user.url_avatar"
                      backgroundColor="#e5e5e5"
                      color="#0097A7"
                      :size="35"
                    ></avatar>
                    <avatar
                      v-else
                      :username="item.user.fullname"
                      backgroundColor="#e5e5e5"
                      color="#0097A7"
                      :size="35"
                    ></avatar>
                  </div>
                </div>
                <span class="seats-number-label">
                  {{ rowTop.code_alpha + (index + 1)}}
                  <!-- {{item.user}} -->
                </span>
                <div v-if="item.user!=undefined && item.user!=null && item.user.id!=null"  class="tooltiptext-bottom">
                  <p  class="p-0 m-0" style="color: #000000">{{item.user.fullname}}</p>
                  <p  class="p-0 m-0" style="color: #707070">{{item.user.company_name}}</p>
                  <p  class="p-0 m-0" style="color: #707070">{{item.user.company_position}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Table and speaker -->
          <div style="display: flex">
            <div
              style="width: 800px; height: 250px; border-radius: 125px; background-color: #CCCCCC; position: relative;"
            >
              <div
                style="width: 600px; height: 75px; background-color: #E4E7E8; border-radius: 125px; position: absolute; top: 50%; left: 50%; margin-right: -50%; transform: translate(-50%, -50%);"
              ></div>
            </div>
          </div>
          <!-- Attendee -->
          <div class="seatsbottom">
            <div class="col-12" v-for="(rowBottom, index) in seatsBottom" :key="rowBottom+index">
              <div
                v-for="(item, index) in rowBottom.data"
                :key="item + index"
                style="width: 52px; height: 61px; border: 1px solid #CCCCCC; display: inline-block; 
                  margin: 5px; border-bottom: 5px solid #c7c7c7c7; position: relative;"
                @click="showModalAddUserForSeats(item, index, rowBottom.code_alpha)"
                class="tooltips-top"
              >
                <!-- {{item}} -->
                <div
                  class="seats-top-user"
                >
                  
                  <div v-if="item.user!=undefined && item.user!=null && item.user.id!=null">
                    <avatar
                      v-if="item.user.url_avatar"
                      :src="item.user.url_avatar"
                      backgroundColor="#e5e5e5"
                      color="#0097A7"
                      :size="35"
                    ></avatar>
                    <avatar
                      v-else
                      :username="item.user.fullname"
                      backgroundColor="#e5e5e5"
                      color="#0097A7"
                      :size="35"
                    ></avatar>
                  </div>
                </div>
                <span class="seats-number-label">{{ rowBottom.code_alpha + (index + 1) }}</span>
                <div class="tooltiptext-top" v-if="item.user!=undefined && item.user!=null && item.user.id!=null">
                  <p  class="p-0 m-0" style="color: #000000">{{item.user.fullname}}</p>
                  <p  class="p-0 m-0" style="color: #707070">{{item.user.company_name}}</p>
                  <p  class="p-0 m-0" style="color: #707070">{{item.user.company_position}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->
    <b-modal
      :title="$t('attendeeList.addUserInSeats')"
      centered
      id="modal-add-user-seats"
      ref="modal-add-user-seats"
    >
      <div class="container">
        <div class="col-12 p-0">
          <label for>{{$t('mission.selectPerson')}}</label>
          <VueMutiselect
            :optionData="userInEventList"
            :customLabel="customLabel"
            :labelText="labelTextValue"
            :trackByText="trackByTextValue"
            :placeHolder="$t('mission.selectPerson')"
            @select="getAssignerSelect"
            :multipleValue="false"
          ></VueMutiselect>
        </div>
        <div class="col-12 p-0 pt-3">{{$t('attendeeList.addUserToPosition')}} 
          <span style="color: #006AD4">{{seats_code_in_html == null ? $t('attendeeList.chairMain') : seats_code_in_html}}</span>
        </div>
      </div>
      <template v-slot:modal-footer>
        <div class="w-100 pl-2 pr-2">
          <b-button
            variant="primary"
            size="sm"
            class="send-reason-absence float-right pl-4 pr-4"
            @click="addUserInSeats"
          >{{$t('common.accept')}}</b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mr-2 pl-4 pr-4"
            style="background-color: #BDBDBD; border: 1px solid #ffffff;"
            @click="$bvModal.hide('modal-add-user-seats')"
          >{{ $t('common.close') }}</b-button>
        </div>
      </template>
    </b-modal>
    <!--  -->
    <snack-bar v-for="snackbar in GET_SNACK_BAR" :key="snackbar.id" :item="snackbar"></snack-bar>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import dateUtils from "utils/dateUtils";
import Avatar from "vue-avatar";
import VueMutiselect from "Components/VueMutiselect";
import functionUtils from "utils/functionUtils"
import moment from 'moment';
import Enum from 'enum/enum';
import TemplateCategoriesEnum from 'enum/templateCategoriesEnum'
// import moment from "moment";

export default {
  components: {
    avatar: Avatar,
    VueMutiselect,
    // UploadExcel
  },
  data: () => ({
    items: [],
    venue: "",
    event: {
      start_datetime: "",
      end_datatime: "",
      name: ''
    },
    table_meeting: {
      id: 0
    },
    seatsTop: [],
    seatsBottom: [],
    userInEventList: [],
    seatsDataSelect: {
      tableMeeting: 0,
      seatsNumber: 0,
      userId: 0,
      codeAlpha: null
    },
    labelTextValue: "full_name",
    trackByTextValue: "full_name",
    // chair main
    chairMain: {
      id: 0,
      full_name: "",
      url_avatar: null,
      company_name: null,
      company_position: null
    },
    total_seats_in_room: null,
    seats_code_in_html: null,
    event_id: null,
  }),
  watch: {
    GET_ROOM_MEETING_INFO_DATA() {
      
      this.table_meeting.id = this.GET_ROOM_MEETING_INFO_DATA.id;
      this.venue = this.GET_ROOM_MEETING_INFO_DATA.venue_data;
      this.total_seats_in_room = this.GET_ROOM_MEETING_INFO_DATA.number_of_seats
      // get seats
      let filter = {
        params: {
          room_meeting: this.table_meeting.id
        }
      };
      // 
      this.GET_SEATS_FOR_MEETING_ROOM(filter);
      this.GET_CHAIR_MAIN(filter);
      let start_time = this.GET_ROOM_MEETING_INFO_DATA.event.start_datetime;
      let end_time = this.GET_ROOM_MEETING_INFO_DATA.event.end_datetime;
      this.event.start_datetime = dateUtils.formatDate(
        start_time,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
        "YYYY-MM-DD HH:mm"
      );
      this.event.end_datatime = dateUtils.formatDate(
        end_time,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE,
        "YYYY-MM-DD HH:mm"
      );
      
    },
    GET_SEATS_FOR_MEETING_ROOM_DATA() {
      let number_seats_in_top = parseInt(this.total_seats_in_room/2);
      let number_row_top = Math.ceil(number_seats_in_top/12)
      let count_looped = 0;
      // top
      let top_array = [];
      for (let i=0; i < number_row_top; i++) {
        top_array.push({row: 0, data:[]})
        for(let j=0; j < number_seats_in_top; j++){
          if(j+1 <= 12){
            count_looped += 1
            let item = top_array[i]
            item.row += 1
            item.data.push({number_seats: count_looped}) 
            let arraySeatsTop = this.GET_SEATS_FOR_MEETING_ROOM_DATA.findIndex(x => x.seats_number === count_looped)
            if (arraySeatsTop !=-1){
              let seats_user_item_top = this.GET_SEATS_FOR_MEETING_ROOM_DATA[arraySeatsTop]
              item.data[j]['seats_number_value']=seats_user_item_top.seats_number
              item.data[j]['table_meeting']=seats_user_item_top.room_meeting
              item.data[j]['user']=seats_user_item_top.user
            }
          }
        }
        number_seats_in_top -= 12
      }
      // bottom
      
      let number_seats_in_bottom = this.total_seats_in_room - count_looped;
      let total_new = number_seats_in_bottom
      let number_row_bottom = Math.ceil(total_new/12)
      let bottom_array = []
      for (let i=0; i < number_row_bottom; i++) {
        bottom_array.push({row: 0, data:[]})
        for(let j=0; j < number_seats_in_bottom; j++){
          if(j+1 <= 12){
            let item = bottom_array[i]
            item.row += 1
          }
        }
        number_seats_in_bottom -= 12
      }
      bottom_array.sort((a, b) => a.row - b.row)
      for (let i=0; i < number_row_bottom; i++) {
        for(let j=0; j < total_new; j++){
            let item = bottom_array[i]
            if(j+1 <= 12){
              if (j+1 <= item.row) {
                count_looped += 1
                item.data.push({number_seats: count_looped}) 
                let arraySeatsBottom = this.GET_SEATS_FOR_MEETING_ROOM_DATA.findIndex(x => x.seats_number === count_looped)
                if (arraySeatsBottom !=-1){
                  let seats_user_item_bottom = this.GET_SEATS_FOR_MEETING_ROOM_DATA[arraySeatsBottom]
                  item.data[j]['seats_number_value']=seats_user_item_bottom.seats_number
                  item.data[j]['table_meeting']=seats_user_item_bottom.room_meeting
                  item.data[j]['user']=seats_user_item_bottom.user
                }
              }
            }
            
        }
        total_new -= bottom_array[i].row
      }
      [top_array, bottom_array]=functionUtils.getCodeAlphaForRow(top_array.concat(bottom_array))
      this.seatsTop = top_array;
      this.seatsBottom = bottom_array;
    },
    GET_USER_IN_EVENT_LIST_DATA() {
      this.userInEventList = [];
      this.GET_USER_IN_EVENT_LIST_DATA.forEach(data => {
        let item = {
          id: data.id,
          full_name: data.lastname + " " + data.firstname,
          url_avatar:
            data.url_avatar != null && data.url_avatar != ""
              ? data.url_avatar
              : null
        };
        this.userInEventList.push(item);
      });
    },
    GET_CHAIR_MAIN_DATA(){
      this.chairMain.id = this.GET_CHAIR_MAIN_DATA.user.id;
      this.chairMain.full_name = this.GET_CHAIR_MAIN_DATA.user.fullname;
      this.chairMain.url_avatar = this.GET_CHAIR_MAIN_DATA.user.url_avatar != null
        && this.GET_CHAIR_MAIN_DATA.user.url_avatar != "" ?
        this.GET_CHAIR_MAIN_DATA.user.url_avatar : null
      this.chairMain.company_name = this.GET_CHAIR_MAIN_DATA.user.company_name;
      this.chairMain.company_position = this.GET_CHAIR_MAIN_DATA.user.company_position;
    },
    EVENT_DETAIL_DATA(){
      this.event.name = this.EVENT_DETAIL_DATA.name;
      // this.event.start_datetime = this.EVENT_DETAIL_DATA.start_datetime;
    },
    GET_CHANGE_UPLOAD_EXCEL_DATA(){
      let results = this.GET_CHANGE_UPLOAD_EXCEL_DATA.results;
      let headers = this.GET_CHANGE_UPLOAD_EXCEL_DATA.headers;
      this.handleSuccess(results, headers)
    }
  },
  computed: {
    ...mapGetters([
      "GET_SNACK_BAR",
      "GET_ROOM_MEETING_INFO_DATA",
      "GET_SEATS_FOR_MEETING_ROOM_DATA",
      "GET_USER_IN_EVENT_LIST_DATA",
      "GET_CHAIR_MAIN_DATA",
      "EVENT_DETAIL_DATA",
      "GET_CURRENT_ACCOUNT",
      "GET_CHANGE_UPLOAD_EXCEL_DATA"
    ])
  },
  methods: {
    // 
    formatEventDateTime: function(startTime, endTime){
      return functionUtils.formatEventDateTime(startTime, endTime)
    },
    // Before upload
    beforeUpload(file) {
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (isLt1M) {
        return true;
      }
      return false;
    },
    /**
     * Upload success
     */
    handleSuccess(results, header) {
      let today = moment(new Date()).utc().format("YYYY-MM-DD");
      let currentDate = moment().format("YYYY-MM-DD");
      let eventDate = dateUtils.formatDate(
        this.event.end_datetime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY-MM-DD"
      );
      if (currentDate > eventDate){
        this.ON_SHOW_TOAST({
          message: this.$t('common.eventHasEnded'),
          styleType: "danger"
        });
      }else if (this.total_seats_in_room == null){
        this.ON_SHOW_TOAST({
          message: this.$t('attendeeList.errorRoomInfo'),
          styleType: "danger"
        });
      }else{
        let position = this.$t('attendeeList.position')
        for (let i = 0, resultsSize = results.length; i < resultsSize; i++) {
          // Top
          for (let j = 0, topSize = this.seatsTop.length; j < topSize; j++) {
            for (let k = 0, dataTopSize = this.seatsTop[j].data.length; k < dataTopSize; k++) {
              let alpha_code_seat_number = this.seatsTop[j].code_alpha + (k + 1)
              if (alpha_code_seat_number == results[i][position]) {
                results[i]['NUMBER_SEATS'] = this.seatsTop[j].data[k].number_seats ? this.seatsTop[j].data[k].number_seats : null
              }
            }
          }
          // Bottom
          for (let j = 0, bottomSize = this.seatsBottom.length; j < bottomSize; j++) {
            for (let k = 0, dataBottomSize = this.seatsBottom[j].data.length; k < dataBottomSize; k++) {
              let alpha_code_seat_number = this.seatsBottom[j].code_alpha + (k + 1)
              if (alpha_code_seat_number == results[i][position]) {
                results[i]['NUMBER_SEATS'] = this.seatsBottom[j].data[k].number_seats ? this.seatsBottom[j].data[k].number_seats : null
              }
            }
          }
        }
        for (let i = 0, size = results.length; i < size; i++) {
          if (functionUtils.isNull(results[i]['NUMBER_SEATS'])) {
            this.ON_SHOW_TOAST({
              message: this.$t('attendeeList.numberSeats'),
              styleType: "danger"
            });
            return
          }
        }
        let eventId = sessionStorage.getItem('event_id')
        let data = {
          headers: header,
          dataImport: results,
          eventId: eventId,
          roomMeeting: this.table_meeting.id,
          currentDate: today
        }; 
        // init empty
        this.resultImportList = []
        this.isLoading = true
        this.IMPORT_USER_FOR_SEATS_EXCEL(data)
          .then(
            function() {
              let filter = {
                params: {
                  room_meeting: this.table_meeting.id
                }
              };
              this.GET_SEATS_FOR_MEETING_ROOM(filter);
              this.GET_CHAIR_MAIN(filter);
              this.ON_SHOW_TOAST({
                message: this.$t('common.success'),
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function() {
              this.ON_SHOW_TOAST({
                message: this.$t('common.somethingWentWrong'),
                styleType: "danger"
              });
            }.bind(this)
          );
      }
    },
    //
    compareDateTimeEvent(startDateTime, endDateTime) {
      let startDateTimeData = dateUtils.formatDate(
        startDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "DD/MM/YYYY"
      );
      let startMonthTimeData = dateUtils.formatDate(
        startDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "DD"
      );
      let endMonthTimeData = dateUtils.formatDate(
        endDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "DD/MM/YYYY"
      );
      //
      let startDateTimeCheck = dateUtils.formatDate(
        startDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY/MM/DD"
      );
      let endDateTimeCheck = dateUtils.formatDate(
        endDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY/MM/DD"
      );
      let startDateMonthCheck = dateUtils.formatDate(
        startDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY/MM"
      );
      let endDateMonthCheck = dateUtils.formatDate(
        endDateTime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY/MM"
      );
      if (startDateTimeCheck == endDateTimeCheck) {
        return startDateTimeData;
      } else if (startDateMonthCheck == endDateMonthCheck) {
        return startMonthTimeData + " - " + endMonthTimeData;
      } else return 0;
    },
    showModalAddUserForSeats(seatsData, index, code_alpha) {
      this.$refs["modal-add-user-seats"].show();
      this.seatsDataSelect.tableMeeting = this.table_meeting.id;
      this.seatsDataSelect.seatsNumber = seatsData.number_seats;
      this.seatsDataSelect.codeAlpha = code_alpha+(index+1);
      this.seats_code_in_html = code_alpha+(index+1);
    },
    addUserInSeats() {
      let today = moment(new Date()).utc().format("YYYY-MM-DD");
      let currentDate = moment().format("YYYY-MM-DD");
      let eventDate = dateUtils.formatDate(
        this.event.end_datetime,
        dateUtils.STATIC_FORMAT_DATE_TIME_ZONE_T,
        "YYYY-MM-DD"
      );
      if (currentDate > eventDate){
        this.ON_SHOW_TOAST({
          message: this.$t('common.eventHasEnded'),
          styleType: "danger"
        });
      }else{
        this.$refs["modal-add-user-seats"].hide();
        let data = {
          seats_number: this.seatsDataSelect.seatsNumber,
          user_id: this.seatsDataSelect.userId,
          room_meeting: this.seatsDataSelect.tableMeeting,
          seats_code: this.seatsDataSelect.codeAlpha,
          currentDate: today,
          event_id: this.event_id
        };
        if (this.seatsDataSelect.seatsNumber!=0){
          this.UPDATE_USER_FOR_SEATS(data)
          .then(
            function() {
              this.sendNotificationConfirmSeats()
              this.sendEmail()
              let filter = {
                params: {
                  room_meeting: this.table_meeting.id
                }
              };
              this.GET_SEATS_FOR_MEETING_ROOM(filter);
              this.GET_CHAIR_MAIN(filter);
              this.ON_SHOW_TOAST({
                message: "Success",
                styleType: "success"
              });
            }.bind(this)
          )
          .catch(
            function(error) {
              let code = error.response.data.code;
              let mess = ""
              if (code=='user_exists_table'){
                mess = this.$t('attendeeList.userExistsTable')
              }
              else mess = error.response.data.message;
              this.ON_SHOW_TOAST({
                message: mess,
                styleType: "danger"
              });
            }.bind(this)
          );
        }else{
          this.UPDATE_CHAIR_MAIN(data)
            .then(
              function() {
                this.sendNotificationConfirmSeats()
                let filter = {
                  params: {
                    room_meeting: this.table_meeting.id
                  }
                };
                this.GET_SEATS_FOR_MEETING_ROOM(filter);
                this.GET_CHAIR_MAIN(filter);
                this.ON_SHOW_TOAST({
                  message: "Success",
                  styleType: "success"
                });
              }.bind(this)
            )
            .catch(
              function(error) {
                let code = error.response.data.code;
                let mess = ""
                if (code=='user_exists_table'){
                  mess = this.$t('attendeeList.userExistsTable')
                }
                else mess = error.response.data.message;
                this.ON_SHOW_TOAST({
                  message: mess,
                  styleType: "danger"
                });
              }.bind(this)
            );
        }
      }
    },
    customLabel({ full_name }) {
      return `${full_name}`;
    },
    getAssignerSelect(data) {
      this.seatsDataSelect.userId = data.id;
    },
    addChairman(){
      this.$refs["modal-add-user-seats"].show();
      this.seatsDataSelect.tableMeeting = this.table_meeting.id;
      this.seatsDataSelect.seatsNumber = 0;
      this.seatsDataSelect.codeAlpha = 'A0';
      this.seats_code_in_html = null;
    },
    sendNotificationConfirmSeats(){
      let data = {
        event_id: this.event_id,
        // message_title: this.event.name,
        // message_body: this.$t('notification.meetingRoom.confirmSeats', {'0': this.seatsDataSelect.codeAlpha, 
        //   '1': this.event.name, '2': this.venue.venue_name}),
        user_id: this.seatsDataSelect.userId,
        send_chairmain_and_secretary: true,
        seat_code: this.seatsDataSelect.codeAlpha,
        venue_name: this.venue.venue_name,
        action_code: TemplateCategoriesEnum.CONFIRM_SEATS,
        template_type: Enum.TemplateType.Notification,
        notification_type_code: Enum.NotificationType.ConfirmSeats
      }
      this.SEND_NOTIFICATION_IN_MEETING(data)

      // send sms
      // let smsContent = this.$t('notification.meetingRoom.confirmSeats', {'0': this.seatsDataSelect.codeAlpha, 
      //     '1': this.event.name, '2': this.venue.venue_name})

      let smsData = {
        event_id: this.event_id,
        // content: functionUtils.uniDecodeString(smsContent),
        user_id: this.seatsDataSelect.userId,
        send_chairmain_and_secretary: true,
        seat_code: this.seatsDataSelect.codeAlpha,
        venue_name: this.venue.venue_name,
        action_code: TemplateCategoriesEnum.CONFIRM_SEATS,
        template_type: Enum.TemplateType.SMS
      }
      this.SEND_SMS_IN_MEETING(smsData)
    },
    sendEmail: function(){
      let data = {
        action_code: TemplateCategoriesEnum.CONFIRM_SEATS,
        template_type: Enum.TemplateType.Email,
        event_id: this.event_id,
        seat_code: this.seatsDataSelect.codeAlpha,
        venue_name: this.venue.venue_name,
        user_id: this.seatsDataSelect.userId,
        send_chairmain_and_secretary: true,
      }
      this.SEND_EMAIL_WITH_TEMPLATE_CONFIG(data)
    },
    ...mapActions([
      "ON_SHOW_TOAST",
      "GET_ROOM_MEETING_INFO",
      "GET_SEATS_FOR_MEETING_ROOM",
      "UPDATE_USER_FOR_SEATS",
      "GET_USER_IN_EVENT_LIST",
      "UPDATE_CHAIR_MAIN",
      "GET_CHAIR_MAIN",
      "GET_EVENT_DETAIL",
      "SEND_NOTIFICATION_IN_MEETING",
      "IMPORT_USER_FOR_SEATS_EXCEL",
      "SEND_SMS_IN_MEETING",
      "SEND_EMAIL_WITH_TEMPLATE_CONFIG"
    ])
  },
  mounted() {
    for (let i = 0, size = 40; i < size; i++) {
      this.items.push(i);
    }
    let event_id = sessionStorage.getItem("event_id");
    this.event_id = event_id;
    let filter = {
      params: {
        event: event_id
      }
    };
    this.GET_ROOM_MEETING_INFO(filter);
    this.GET_USER_IN_EVENT_LIST(filter);
    let event = {
      params: {
        is_meeting: true
      },
      id: event_id
    }
    this.GET_EVENT_DETAIL(event);
  }
};
</script>
<style lang="scss" scoped>
.centered {
  margin-left: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.tooltips-bottom .tooltiptext-bottom {
  visibility: hidden;
  width: 140px;
  background-color: #ECEFF0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 110%;
  left: 50%;
  margin-left: -70px;
}
.tooltips-bottom .tooltiptext-bottom::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color:transparent transparent #ECEFF0 transparent;
}
.tooltips-bottom:hover .tooltiptext-bottom {
  visibility: visible;
}
// 
.tooltips-top .tooltiptext-top {
  visibility: hidden;
  width: 140px;
  background-color: #ECEFF0;
  color: #fff;
  font-size: 14px;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 110%;
  left: 50%;
  margin-left: -70px;
}

.tooltips-top .tooltiptext-top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #ECEFF0 transparent transparent transparent;
}

.tooltips-top:hover .tooltiptext-top {
  visibility: visible;
}

.seats-top-not-user{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.seats-top-user{
  position: absolute;
  top: 39%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.seats-number-label{
  transform: translate(-50%, 34%) !important;
  position: absolute;
  top: 31px;
  position: absolute;
  font-size: 14px;
}
</style>
